<template>
    <div class="mt-10">
        <div class="card" v-show="loading">
            <div class="card-body d-flex flex-column p-9">
                <el-skeleton />
            </div>
        </div>
        <div class="w-100" v-show="!loading">
            <div class="row g-5 g-xl-8 mb-5">
                <div class="col-lg-12" v-if="statistic.common && statistic.common.sale_report">
                    <div class="card">
                        <div class="card-header border-0 pt-5">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bolder fs-3 mb-1">{{ $t('pages.statistic.product.specific.common.saleReport.title')}}</span>
                            </h3>

                            <div class="card-toolbar" data-kt-buttons="true">
                                <a v-for="(reportIndex, reportKey, report) in statistic.common.sale_report" :key="reportKey" :class="common.saleReport.tab == reportKey && 'active'" @click="changeCommonSaleReportTab(reportKey)" class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1">{{ $t('common.' + reportKey)}}</a>

                                <el-tooltip class="item ms-2" effect="dark" :content="$t('btn.filter')" placement="top">
                                    <el-button size="small" data-kt-menu-trigger="click" data-kt-menu-overflow="true" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
                                        <i class="bi bi-funnel"></i>
                                    </el-button>
                                </el-tooltip>

                                <StatisticProductSpecificSaleReportFilter id="filterBox" @onFilter="onCommonSaleReportFilter" ref="filterComponent"></StatisticProductSpecificSaleReportFilter>
                            </div>
                        </div>

                        <div class="card-body" v-if="common.saleReport.showChart">
                            <apexchart type="line" :options="common.saleReport.options" :series="common.saleReport.series" :height="350"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCSSVariableValue } from "@/assets/js/_utils";
import {MenuComponent} from "@/assets/js/components/MenuComponent";

import StatisticProductSpecificSaleReportFilter from "@/components/filter/statistic/product/specific/sale-report"
export default {
    name: "index",
    components: {
        'StatisticProductSpecificSaleReportFilter': StatisticProductSpecificSaleReportFilter
    },
    props: {
        products: {type: Array, default: () => { return []}}
    },
    data(){
        return {
            statistic: {},
            common: {
                saleReport: {
                    tab: "year",
                    options: {
                        chart: {
                            height: 350,
                            type: 'line',
                            zoom: {
                                enabled: false
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            width: 3,
                            curve: 'straight',
                            dashArray: 0
                        },
                        title: {
                            text: this.$t('pages.statistic.product.specific.common.saleReport.title'),
                            align: 'left'
                        },
                        legend: {
                            tooltipHoverFormatter: function(val, opts) {
                                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
                            }
                        },
                        markers: {
                            size: 0,
                            hover: {
                                sizeOffset: 6
                            }
                        },
                        xaxis: {
                            categories: [],
                        },
                        tooltip: {
                            y: {
                                formatter:  (val) => {
                                    return parseInt(val)
                                },
                            },
                        },
                        grid: {
                            borderColor: '#f1f1f1',
                        }
                    },
                    series: [],
                    showChart: false,
                }
            },
            loading: false
        }
    },
    methods: {
        loadStatistic(filterData = {}){
            if(!this.products ||!Array.isArray(this.products) || !this.products.length){
                return;
            }

            this.setLoading(true);

            this.axios.post(this.endpoints['statistic_product_specific'], Object.assign(filterData, {products: this.products})).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.statistic = data;
                    this.changeCommonSaleReportTab(this.common.saleReport.tab);

                    setTimeout(() => {
                        MenuComponent.reinitialization();
                    }, 500);
                }, undefined, false);

            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.setLoading(false);
            });
        },
        setLoading(status) {
            this.$emit('loading', status);
            this.loading = status;
        },
        onCommonSaleReportFilter(filterData){
            this.loadStatistic(filterData);
        },
        changeCommonSaleReportTab(tab) {
            this.common.saleReport.showChart = false;
            this.common.saleReport.tab = this.cloneData(tab);

            let statistic = this.statistic.common.sale_report[tab];

            let categories = [];
            let series = [];

            let regulatedData = {};
            statistic.forEach((data) => {
                if(categories.indexOf(data.order_date_format) === -1) {
                    categories.push(data.order_date_format);
                }

                if(!regulatedData[data.product_id]) {
                    regulatedData[data.product_id] = {
                        id: data.product_id,
                        title: data.product_title
                    };
                }

                regulatedData[data.product_id][data.order_date_format] = {
                    sale_count: data.sale_count
                };
            });

            let productIndex = 0;
            for(let productID in regulatedData) {
                let product = regulatedData[productID];

                series.push({name: this.sprintf('%s#%d', [product.title, product.id]), data: []});

                categories.forEach((date) => {
                    let productDateData = product[date];
                    series[productIndex].data.push((productDateData) ? parseInt(productDateData.sale_count) : 0);
                })

                productIndex++;
            }

            this.common.saleReport.options.xaxis.categories = categories;
            this.common.saleReport.series = series;

            this.$nextTick(() => {
                this.common.saleReport.showChart = true;
            })
        }
    }
}
</script>

<style scoped>

</style>